<template>
  <div>
    <div class="full-div">
      <div class="mt-4 d-flex justify-content-end full-widht">
        <span class="pagination-info mr-auto" :class="{ hidden: !showText }"
          >{{ $t('showing') }} {{ startIndex }} {{ $t('to') }} {{ endIndex }}
          {{ $t('of') }} {{ total }} {{ $t('results') }}</span
        >

        <div style="width: 220px">
          <label class="float-left mr-2">{{ $t('rowsPerPage') }}:</label>
          <filter-select
            class="float-left mt-n2"
            :value="pageSize"
            fieldtext="text"
            fieldvalue="value"
            label=""
            :clearable="false"
            :options="perPageOptions"
            @on-selection-changed="
              $emit('pagination-rows-per-page', $event.value)
            "
          />
        </div>
        <div v-click-outside="removeEditMode">
          <div v-show="editMode">
            <filter-numeric
              class="mt-n2"
              v-model="selectedPage"
              :max="data.pageCount"
              :min="1"
            />
          </div>

          <ul v-show="!editMode" class="pagination mt-n2">
            <li v-if="currentPage > 1" class="mr-1 rounded bg-light-orange">
              <a :id="`${id}-GoToFirstPage`" @click="goToPage(1)">
                <i class="fas fa-angle-double-left" />
              </a>
            </li>
            <li v-if="currentPage > 1"  class="mr-2 rounded bg-light-orange">
              <a :id="`${id}-GoToPrevious`" @click="goToPage(currentPage - 1)">
                <i class="fa fa-angle-left" />
              </a>
            </li>

            <li
              v-for="page in pages"
              :key="page"
              class="custom-border rounded border-top border-bottom"
              :class="{ active: page === currentPage, disabled: page === '...' }"
            >
              <a :id="`${id}-GoToPage-${page}`" @click="goToPage(page)">{{
                page
              }}</a>
            </li>

            <li v-if="currentPage != lastPage" class="ml-2 rounded bg-light-orange">
              <a
                :id="`${id}-GoToNext`"
                @click="goToPage(currentPage + 1)"
              >
                <i class="fa fa-angle-right" />
              </a>
            </li>
            <li v-if="currentPage != lastPage" class="ml-1 rounded bg-light-orange">
              <a
                :id="`${id}-GoToLastPage`"
                @click="goToPage(lastPage)"
              >
                <i class="fas fa-angle-double-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mobile-div">
      <div class="row">
        <div class="col-12">
          <span
            class="pagination-info w-100 pagination-info-mobile"
            :class="{ hidden: !showText }"
            >{{ $t('showing') }} {{ startIndex }} {{ $t('to') }} {{ endIndex }}
            {{ $t('of') }} {{ total }} {{ $t('results') }}</span
          >
        </div>
        <div class="col-md-12 mt-2">
          <label class="float-left mr-2">{{ $t('rowsPerPage') }}:</label>
          <filter-select
            class="float-left mt-n2"
            :value="pageSize"
            fieldtext="text"
            fieldvalue="value"
            label=""
            :clearable="false"
            :options="perPageOptions"
            @on-selection-changed="
              $emit('pagination-rows-per-page', $event.value)
            "
          />
        </div>
        <div v-click-outside="removeMobileEditMode" class="col-12">
          <div class="justify-content-between">
            <div v-show="mobileEditMode">
              <filter-numeric
                class="mt-n2"
                v-model="selectedPage"
                :max="data.pageCount"
                :min="1"
              />
            </div>
            <ul v-show="!mobileEditMode" class="pagination mr-3 mobile">
              <li class="mr-1 rounded bg-light-gray col">
                <a
                  :id="`${id}-GoToPrevious`"
                  @click="goToPage(currentPage - 1)"
                >
                  <i class="fa fa-chevron-left" />
                </a>
              </li>

              <li
                v-for="page in pagesMobile"
                :key="page"
                class="custom-border rounded border-top border-bottom col"
                :class="{ activeMobile: page === currentPage }"
              >
                <a
                  :id="`${id}-GoToPage-${page}`"
                  :class="{ activeMobile: page === currentPage }"
                  @click="goToPage(page)"
                  >{{ page }}</a
                >
              </li>

              <!-- last page -->
              <li
                v-if="pages.length > 1"
                class="
                  rounded
                  custom-border-last
                  border-top border-bottom border-right
                  col
                "
                :class="{ activeMobile: lastPage === currentPage }"
              >
                <a
                  :id="`${id}-GoToPage-${lastPage}`"
                  :class="{ activeMobile: lastPage === currentPage }"
                  @click="goToPage(lastPage)"
                  >{{ lastPage }}</a
                >
              </li>

              <li class="ml-1 rounded bg-light-gray col">
                <a :id="`${id}-GoToNext`" @click="goToPage(currentPage + 1)">
                  <i class="fa fa-chevron-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  meta: {
    description: 'Indica contenido relacionado en varias páginas.',
    slots: {
      default: {
        type: 'component',
        valid: [],
      },
    },
  },
  props: {
    id: {
      type: String,
      default: 'pgPager',
      meta: {
        description: 'Id del Componente de paginacion',
        example: 'pgPager',
      },
    },
    showText: {
      type: Boolean,
      required: false,
      default: true,
    },
    data: {
      type: Object,
      default() {
        return {
          currentPage: 0,
          totalItemCount: 0,
          pageCount: 0,
          count: 0,
        };
      },
      meta: {
        description: 'Establece la data relacionada al componente',
        example: '',
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      perPageOptions: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 40, text: '40' },
      ],
      editMode: false,
      mobileEditMode: false,
      selectedPage: 1,
      timeOutSearch: 0,
    };
  },
  computed: {
    pages() {
      const pages = [];
      const range = 1;
      const page = this.currentPage;
      const count = this.data.pageCount;

      for (let i = 1; i <= count; i++) {
        if (i == 1 && page - range > 1) {
            pages.push(1);
            if (page -range > 2){
                pages.push('...');
            }
        }
        if (i >= page-range && i <= page+range) {
            pages.push(i);
        }
        if (i == count && page+range < count) {
            if (page + range < count -1) {
                pages.push('...');
            }
            pages.push(count);
        }
      }
      return pages;
    },

    pagesMobile() {
      const pages = [];
      const offset = 1;
      let endAt = 1;
      let startAt = 1;

      if (
        this.currentPage > 2 &&
        this.data.pageCount > this.currentPage + offset
      ) {
        endAt = this.currentPage + offset;
        startAt = this.currentPage - offset;
      }
      if (startAt < 0) startAt = 1;

      let qtyButtons = 0;

      if (this.data.pageCount <= endAt) {
        qtyButtons = this.data.pageCount;
      } else {
        qtyButtons = endAt;
      }

      for (let i = startAt; i <= qtyButtons; i++) {
        pages.push(i);
      }

      if (this.currentPage != this.lastPage) return [this.currentPage];

      return pages;
    },

    total() {
      return this.data.totalItemCount;
    },
    startIndex() {
      // This is the correct, keep this on merge
      return (this.currentPage - 1) * this.pageSize + 1;
    },
    endIndex() {
      // This is the correct, keep this on merge
      let limit = this.currentPage * this.pageSize;
      if (this.data.totalItemCount > limit) {
        return limit;
      } else {
        return this.data.totalItemCount;
      }
    },
    currentPage() {
      return this.data.currentPage;
    },
    lastPage() {
      return this.data.pageCount;
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.selectedPage = val;
      },
    },
    selectedPage(val) {
      clearTimeout(this.timeOutSearch);
      if (this.currentPage == val) return;
      this.timeOutSearch = setTimeout(
        async () => this.goToPage(val),
        1500,
      );
    },
  },
  methods: {
    removeEditMode() {
      this.editMode = false;
    },
    removeMobileEditMode() {
      this.mobileEditMode = false;
    },
    goToPage(pageNumber) {
      if (pageNumber == this.currentPage) {
        this.editMode = true;
        this.mobileEditMode = true;
        return;
      }
      if (pageNumber < 1 || pageNumber > this.data.pageCount) return;
      if (pageNumber !== this.currentPage) {
        this.$emit('pagination-go-page', pageNumber);
      }
    },
  },
};
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}

.bg-light-orange {
  background-color: #FFF7EF;
  border: 1px solid #FF8105;
  a > i {
    color: #FF8105;
  }

}
.disabled {
   pointer-events: none;
}

.pagination-info {
  text-align: left;
  font: Regular 16px/19px Lato;
  letter-spacing: 0;
  color: black;
  opacity: 1;
}
.pagination {
  font: Bold 14px/17px Lato;
}
.pagination > li > a {
  width: 33px;
  height: 34px;
  position: relative;
  float: left;
  text-align: center;
  line-height: 34px;

  letter-spacing: 0;
  color: $color-font-success;
  text-decoration: none;
}
.pagination > ul > li {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border: 1px solid $color-label-default;
  border-radius: 4px;
}

.pagination > li.active {
  background: $color-primary 0% 0% no-repeat padding-box;
  color: $color-white;
  border-radius: 4px;
}
.pagination > li.active a {
  color: $color-white;
}
.rounded {
  border-radius: 4px;
}

.custom-border {
  border: 1px solid #dee2e6 !important;
  // border-top-right-radius: unset !important;
  // border-bottom-right-radius: unset !important;
}
.custom-border-last {
  border-left: unset !important;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  margin-left: -4px;
}

.full-div {
  margin-bottom: 100px;
}

/* Mobile */
ul.mobile > li:not(.activeMobile) {
  background: #f2f2f2;
  border: 1px solid $color-label-default;
  border-radius: 4px;
  color: #727273;
}

.pagination > li a.activeMobile {
  background: $color-primary 0% 0% no-repeat padding-box;
  color: $color-white;
  border-radius: 4px;
}

ul.mobile > li > a {
  margin: 0 auto;
  width: 100%;
  display: block;
}

.pagination-info-mobile {
  font-size: 12px;
}

.mobile-div {
  display: none;
}

@media only screen and (max-width: 768px) {
  .full-div {
    display: none;
  }
  .mobile-div {
    display: block;
  }
}
</style>
